import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import PageGeneratorComponent from './components/organisms/PageGenerator/PageGeneratorComponent';
import Survey from "./pages/Survey";
import SurveyOnboardingPage from "./pages/SurveyOnboardingPage";
import Section from "./pages/Section";
import Results from "./pages/Results";

import AdditionalComments from "./pages/AdditionalComments";
import MainLayout from "./components/molecules/layouts/MainLayout";
import MainSurveyLayout from "./components/molecules/layouts/MainSurveyLayout";
import Feedback from "./components/organisms/Feedback";

export class Routes extends Component {
  render() {
    return (
      <BrowserRouter >
        <Switch>
          <Route path='/survey/results' exact>
            <MainLayout>
              <Switch>
                <Route path='/survey/results' exact component={PageGeneratorComponent} />
             </Switch>
            </MainLayout>
          </Route>


          <Route path='/survey/:path/:n?'>
            <MainSurveyLayout>
              <Switch>
                <Route path='/survey' exact component={Survey} />
                <Route path='/survey/section/:sectionNumber' component={Section} />
                <Route path='/survey/additional-comments' exact component={AdditionalComments} />
                
                <Route component={(props) => <PageGeneratorComponent {...props} isPageNotFound={true}/>} exact path='/survey/*' />
              </Switch>
            </MainSurveyLayout>
          </Route>
          <Route>
            <MainLayout>
              <Switch>
                <Route path='/' exact component={ PageGeneratorComponent } />
                <Route path='/feedback' exact component={ Feedback } />
                <Route path='/survey-onboarding' exact component={PageGeneratorComponent} />
                {
                  (process.env.REACT_APP_REDIRECTS?.split(",") || []).map((redirectKeyword, redirectKeywordIndex) => {
                    <Route path={redirectKeyword} exact component={PageGeneratorComponent} />    
                  })
                }
                <Route component={(props) => <PageGeneratorComponent {...props} isPageNotFound={true}/>} isErrorPage={true} exact path="/*" />
              </Switch>
            </MainLayout>
          </Route>
        </Switch>
      </BrowserRouter>
    )
  }
}
import React from 'react';
import { useSelector } from 'react-redux';
import PageIssue from '../../molecules/PageIssue';
import { GetServerErrorConfig } from '../../../utility';
import logo from '../../../assets/images/logo-pt_PT.svg'
import LazyLoadImage from '../../atoms/image/Image';

const Header = () => {
  return (
    <header className="header pt-12 sm:pt-6 md:pt-12 lg:pt-12 2xl:pt-12 3xl:pt-12 px-20 sm:px-10 md:px-20 xl:px-20 lg:px-20 2xl:px-20 3xl:px-20 4xl:px-20">
      <div className="header__container container">
        <a href="/">
          <LazyLoadImage src={logo} className="logo" alt="logo" />
        </a>
      </div>
    </header>
  )
}

const GlobalError = (props) => {
  const error = useSelector((state) => state.error);
  const serverError = useSelector((state) => state.serverError);
  const configuration = useSelector((state) => state.configuration);
  const { pageError } = configuration || {};
  const { content } = pageError || {};
  const { components } = content || [];

  if (error && components) {
    return (
      <>
        <Header />
        <PageIssue data={components && components[0]} isStatic={false} />
      </>
    )
  }
  if (error && !components) {
    return (
      <>
        <Header />
        <PageIssue data={GetServerErrorConfig()?.content?.components[0]} isStatic={true} />
      </>
    )
  }
  if (serverError) {
    return (
      <>
        <Header />
        <PageIssue data={GetServerErrorConfig()?.content?.components[0]} isStatic={true} />
      </>
    )
  }
  return props.children;
}

export default GlobalError;

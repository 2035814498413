import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.scss';
import LazyLoadImage from '../../atoms/image/Image';
import { OPTINCTA_COMPONENT, GILD_EVENT, BUTTONS, CLICKED } from '../../../constants';
import {PushDataLayerObject} from '../../../utility';

  const Anchor = ({data, onClick, tabIndex}) => {

    const configuration = useSelector((state) => state.configuration);

    const object = data.id === OPTINCTA_COMPONENT ? data.object.cta : data.object;
    const eventDetails = data.id === OPTINCTA_COMPONENT ? null : data.object.event;

    const onClickAnchor = (e) => {
      if (configuration && eventDetails) {
        PushDataLayerObject(
          configuration,
          GILD_EVENT, 
          BUTTONS,
          CLICKED,
          eventDetails.label
          )
      }
      if(onClick)onClick(e);
    }

  return (data &&
    <div className='cta-div text-center'>
      <Link role="button" tabIndex={tabIndex} onClick={onClickAnchor} className='place-content-center mt-10 px-6 text-center bg-primary-900 hover:bg-opacity-75 transition-colors duration-200 rounded-full font-semibold py-4 xs:min-w-17 2xs:min-w-20 3xs:min-w-22 4xl:min-w-26 inline-flex cta' to={ object.link }>
           {object.text}
           {(object.iconImage && object.iconImage !== '') &&
               <LazyLoadImage className='w-5 ml-2' src={object.iconImage} alt={object.text} />
           }
       </Link>
    </div>
  )
}

export default Anchor;
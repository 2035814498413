import React from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import pdfImage from './images/pdfImage.png';
import {REACT_APP_PDF_FONT_REGULAR, REACT_APP_PDF_FONT_BOLD, Q_MCQ, Q_YES_NO_WITH_TEXT, Q_YES_NO_WITH_MULTIPLE_TEXT} from '../../../constants';

// Registering of the font for this PDF would require pure TTF font - better be from gstatic source.
Font.register({
  family: 'Rubik',
  fonts: [
    {src: `${REACT_APP_PDF_FONT_REGULAR}`, fontStyle:'regular'},
    {src: `${REACT_APP_PDF_FONT_BOLD}`, fontStyle:'bold'}
  ]
});
// Create styles
const styles = StyleSheet.create({
  page: {
    borderTop: 15,
    borderBottom: 30,
    borderLeft: 30,
    borderRight: 30,
    paddingBottom: 95
  }, 
  pageTop: {
    backgroundColor : '#C50F3C',
    height: 15,
    position: 'absolute',
    width: '100%',
    top: -15,
    left: 0,
    right: 0,
  },
  companyName: {
    fontSize: 9,
    marginTop: 12,
    marginBottom:70,
    flexWrap: 'wrap',
    //fontFamily: '"Poppins", sans-serif',
    fontFamily: 'Rubik',
    fontStyle: 'bold',
    color: '#000000'
  },
  section: {
    margin: '30'
  },
  containerWrapper: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    paddingBottom: 10,
    minHeight:85
  },
  image: {
    marginBottom: 10,
    width: 190,
    marginLeft: 70
  },
  leftColumn: {
    flexDirection: 'column',
    width: 280,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  heading: {
    fontSize: 18,
    marginTop: 10,
    marginBottom:10,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    color: '#000000',
    hyphens: 'none'
  },
  heading2: {
    fontSize: 16,
    margingTop: 40,
    marginBottom:5,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    color: '#000000',
    hyphens: 'none'
  },
  heading2Wrapper: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
  },
  introText: {
    fontSize:10,
    marginBottom: 5,
    color: '#000000',
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    hyphens: 'none'
  },
  introText2: {
    fontSize:10,
    marginBottom: 5,
    color: '#000000',
    fontStyle: 'regular',
    fontFamily: 'Rubik',
    hyphens: 'none'
  },
  resultsWrapper: {
    marginTop:1,
  },
  section: {
    fontSize:12,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    marginBottom:8,
    color: '#000000'
  },
  sectionWrapper: {
    marginTop: 20,
    marginBottom: 8
  },
  question: {
    fontSize:10,
    marginBottom:6,
    justifyContent: 'space-around'
  },
  questionWrapper: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    paddingTop: 10,
    paddingBottom: 6,
    borderBottom: 0.25,
    borderBottomColor: '#bcbcbe',
    borderBottomStyle: 'solid',
  },
  leftText: {
    flexDirection: 'column',
    width: 373,
    fontStyle: 'regular',
    fontFamily: 'Rubik',
    fontSize:9,
    color: '#000000'
  },
  rightText: {
    flexDirection: 'column',
    width: 162,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000',
    textAlign: 'right'
  },
  additionalText: {
    marginTop: 12,
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000'
  },
  additionalAnswer: {
    fontStyle: 'bold',
    fontFamily: 'Rubik',
    fontSize:9,
    fontWeight: 'bold',
    color: '#000000'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: -15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  t1: {
    color: '#147b5d',
    backgroundColor: '#147b5d',
    height: 5,
    marginBottom: 25
  },
  t2: {
    color: '#EE8E30',
    backgroundColor: '#EE8E30',
    height: 5,
    marginBottom: 25
  },
  t3: {
    color: '#C50F3C',
    backgroundColor: '#C50F3C',
    height: 5,
    marginBottom: 25
  }


});

const ResultsDoc = ({ pdfData, results, additionalComments }) => {

  const { resultsState } = results;
  const { pdfMeta } = pdfData;
  const { companyName, heading, text, subHeading, subTexts, additionalCommentsEmpty, page } = pdfMeta || {};
  const { additionalCommentsState } = additionalComments;
  const additionalCommentsToPrint = (additionalCommentsState.object.answer.text) ? additionalCommentsState.object.answer.text: additionalCommentsEmpty;
  const groupedStatus = {
     GROUPED_NONE: 0,
     GROUPED_NEW: 1,
     GROUPED_CONTINUED: 2
  }
  let grouped = groupedStatus.GROUPED_NONE;
  let mainQuestionNumber = 0;
    return (
        <Document>
          <Page size="A4" wrap style={styles.page}>
            <View fixed>
              <View style={styles.pageTop}>
                  <Text>&nbsp;</Text>
              </View>
              <Text style={styles.companyName}>{ companyName }</Text>
            </View>
            <View style={ styles.section }>
              <View style={styles.containerWrapper}>
                  <View style={styles.leftColumn}>
                      <Text style={styles.heading}>{ heading }</Text>
                      <Text style={ styles.introText}>{ text }</Text>
                      {(subTexts || []).map((subText, subTextIndex) => {
                        return (
                          <View key={subTextIndex}>
                            <Text style={ styles.introText2 } >{ subText }</Text>
                          </View>
                      ) 
                      })}
                  </View>
                  <View style={styles.leftColumn}>
                      <Image style={ styles.image } src={pdfImage} />
                  </View>
              </View>
            </View>
            <View style={ styles.section }>   
              {(resultsState || []).map((section, sectionIndex) => {
                let sectionTier = "T1";
                return (
                  <View key={sectionIndex}>
                      <View>
                          <Text style={styles.introText}>{section.name}</Text>
                          <View key={sectionIndex}>
                              {
                              section.questions.map((quest, questIndex) => {
                                  const answer = quest.answer.find(x =>x.answered === 'true');
                                  const message = answer && ( answer.score === 'T2' || answer.score === 'T3')? "- " + answer.scoreMessage: "";
                                  if (answer.score === 'T2' && sectionTier === "T1")
                                  {
                                      sectionTier = answer.score;
                                  }
                                  if (answer.score === 'T3' && sectionTier != "T3")
                                  {
                                      sectionTier = answer.score;
                                  }
                                  if(!answer) return;

                                  return (
                                      (answer.score != "T1") ? 
                                  <View key={questIndex}>
                                      <Text style={styles.introText2}>{message}</Text>
                                  </View>
                                  : <View key={questIndex}></View>
                                  )
                              })
                              }
                          </View>
                      </View>
                      <Text style={(sectionTier) === "T1"? styles.t1 : ((sectionTier) === "T2"? styles.t2 : styles.t3)}>{sectionTier}</Text>
                  </View>
                ) 
              })}
              <View style={styles.heading2Wrapper} break>
                <Text style={ styles.heading2 }>{ subHeading }</Text>
              </View>
              <View style={styles.resultsWrapper}>
                {(resultsState || []).map((section, sectionIndex) => {
                    return (
                        <View key={sectionIndex} style={styles.sectionWrapper}>
                          <Text style={ styles.section }>{section.name}</Text>
                          <View key={sectionIndex}>
                              {
                              section.questions.map((quest, questIndex) => {
                                  const data = quest.answer.find(x =>x.answered === 'true');
                                  const message = data && (data.score === 'T1' || data.score === 'T2' || data.score === 'T3')? "-" + data.scoreMessage: "";
                                  let questionCountDigit =  ". ";

                                  if (questIndex === 0) //New Section's questions
                                    mainQuestionNumber = 0; //Reset Main Question Number

                                  grouped = groupedStatus.GROUPED_NONE; //Default state of grouped questions 

                                  if (quest.id === Q_MCQ && quest.properties[0].grouped === 'true') {
                                    if (quest.properties[0].groupQuestion) 
                                      grouped = groupedStatus.GROUPED_NEW; //Brand new grouped question
                                    else   
                                      grouped = groupedStatus.GROUPED_CONTINUED; //Continued grouped question without Grouped Question Text
                                  }

                                  if (grouped === groupedStatus.GROUPED_NONE || grouped === groupedStatus.GROUPED_NEW)
                                    mainQuestionNumber++;

                                  if (grouped === groupedStatus.GROUPED_CONTINUED) {
                                    questionCountDigit = "";
                                  } 
                                  else 
                                    questionCountDigit = mainQuestionNumber + questionCountDigit;
                                    

                                  const answerYesNoWithText = (quest.id === Q_YES_NO_WITH_TEXT && quest.answer[0].answered === 'true')? quest.subquestion.answer[0].text : "";
                                  const answerYesNoWithTextMultiple = (quest.id === Q_YES_NO_WITH_MULTIPLE_TEXT && quest.answer[0].answered === 'true')? quest.subquestion[0].answer: "";
                                  
                                  return (
                                  <View key={questIndex} style = {styles.questionWrapper}>
                                    <View style={ styles.leftText} >
                                      {
                                        ((grouped === groupedStatus.GROUPED_NEW) && <Text>{questionCountDigit}{quest.properties[0].groupQuestion}</Text>)
                                      }
                                      <Text>{(grouped === groupedStatus.GROUPED_NEW)? "\n\r": questionCountDigit}{quest.question}</Text>
                                      {
                                        ((answerYesNoWithText != "") && <Text style={ styles.additionalText}>{answerYesNoWithText}</Text>)
                                      }
                                      {(answerYesNoWithTextMultiple != "") && (answerYesNoWithTextMultiple).map((ans, ansIndex) => {
                                        return(
                                          <Text style={styles.additionalAnswer} key={ansIndex}>{(ansIndex>0)? "- " + ans.text:" "}</Text>
                                          )
                                        })
                                      }
                                    </View>
                                    <View style={ styles.rightText} >
                                      <Text>{data.text}</Text>
                                    </View>  
                                  </View>
                                  )
                              })
                              } 
                          </View>
                        </View>  
                    ) 
                    })}
                    <View key={'additional-comments'} style={styles.sectionWrapper}>
                          <Text style={ styles.section }>{additionalCommentsState.heading}</Text>
                          <View key={'additional-comments'}>
                            {  
                              <View key={'additional-comments-section'} style = {styles.questionWrapper}>
                                <View style={ styles.leftText} >
                                  <Text style={styles.additionalAnswer} key={'comments-text'}>{additionalCommentsToPrint}</Text>
                                </View>
                              </View>
                            } 
                          </View>
                    </View>  
              </View>
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `${page} ${pageNumber} / ${totalPages}`
            )} fixed />
          </Page>
        </Document>
    );
}

export default ResultsDoc;
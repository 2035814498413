import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionComponent from '../Section/SectionComponent';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MCQ from '../../molecules/survey/MCQ';
import YesNo from '../../molecules/survey/YesNo';
import YesNoWithText from '../../molecules/survey/YesNoWithText';
import Rating from '../../molecules/survey/Rating';
import qs from 'query-string';
import YesNoWithMultiText from '../../molecules/survey/YesNoWithMultiText';
import { updateSectionPageNumber, getSurveyPages, setVisitedPages, setCompletedPages } from '../../../redux/actions/configuration'
import { useLocation, Switch, Link } from 'react-router-dom';

import {
  Q_YES_NO_WITH_MULTIPLE_TEXT, Q_YES_NO_WITH_TEXT, Q_YES_NO, Q_MCQ, SURVEY,
  Q_RATING, PATH_PAGE_SURVEY_RESULT, PATH_PAGE_SURVEY_SECTION, PATH_PAGE_SECTION,
  PATH_PAGE_LOCALE, PATH_PAGE_SURVEY_ADDITIONAL_COMMENTS, PATH_REDIRECT_USER,
  Q_ADDITIONAL_COMMENTS, GILD_EVENT, BUTTONS, CLICKED,ANSWERED, NEXT_SECTION, NEXT_ADDTIONAL_COMMENTS,
  NEXT_SURVEY_RESULTS, ANSWERING_QUESTION, SURVEY_SECTION, PATH_PAGE_LOCALE_DEFAULT
} from '../../../constants';
import {PushDataLayerObject} from '../../../utility';


const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
  return props.children
}

function _ScrollToView(props) {
  console.log('here');
  const { key, id } = props || {};
  console.log({
    props,
    key,
    id,
  })

  if(key === 0) {
    window.scroll({
      top: document.getElementById(id).offsetTop,
      left: 0,
      behavior: 'smooth'
    });
    return;
  }

  window.scroll({
    top: parseInt(document.getElementById(id).offsetHeight) * parseInt(key),
    left: 0,
    behavior: 'smooth'
  });

}

const SectionComponent1 = () => {
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm();
  const configuration = useSelector((state) => state.configuration);
  const history = useHistory();
  const [questionsState, setQuestionsState] = useState([]);
  const [headingState, setHeadingState] = useState({});
  const dispatch = useDispatch();
  const currentSectionNumber = useSelector((state) => state.currentSectionNumber);
  const acceptedTCs = useSelector((state) => state.acceptedTCs);
  const url = usePathname();
  const queryParam = qs.parse(location.search);
  const [link, setLink] = useState(url + PATH_PAGE_LOCALE + queryParam.locale);
  const [nextButtonText, setNextButtonText] = useState('Next');
  const [showNext, setShowNext] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [additionalCommentsExist, setAdditionalCommentsExist] = useState(false);
  let selectAnswers = [];
  const { translations } = configuration?.content?.content?.labels?.sections[4] || [];
  
  let questionCountInPage = questionsState.filter(function(question){

    const grouped = typeof question.properties[0].grouped !== 'undefined' && question.properties[0].grouped === 'true';

  return !grouped || (grouped && question.properties[0].groupQuestion.length > 0);
}).length;

  const visitedPages = useSelector((state) => state.visitedPages);
  const completedPages = useSelector((state) => state.completedPages);

  const { survey } = configuration;

  const redirectIfNonAcceptedTCs = () => {
    const url = window.location.href;
    let splitedUrl = url.split('?');
    let locale = splitedUrl[1]?.split('=')[1];

    if (acceptedTCs === false) {
      let path = `?locale=${locale ? locale : PATH_PAGE_LOCALE_DEFAULT}`;
      history.push(PATH_REDIRECT_USER + path);
    }
  }

  React.useEffect(() => {

    if (url) {
      const values = url.split(PATH_PAGE_SECTION);
      dispatch(updateSectionPageNumber(values[1].substring(0, 1)));
    }

    if (survey) {
      const cSurveyIndex = survey.content.components.findIndex(x => x.id === SURVEY);
      const cSurvey = survey.content.components.find(x => x.id === SURVEY);
      const cCtaNextSection = survey.content.components[cSurveyIndex + 1];
      setAdditionalCommentsExist(survey.content.components.findIndex(x => x.id === Q_ADDITIONAL_COMMENTS) !== -1);

      setNextButtonText(cCtaNextSection.object.text);

      const section = cSurvey.object.sections[parseInt(currentSectionNumber) - 1];
      const surveyPages = { pages: [0] };
      surveyPages.pages = cSurvey.object.sections;
      dispatch(getSurveyPages(surveyPages));
      visitedPages.push(parseInt(currentSectionNumber) - 1);
      dispatch(setVisitedPages(visitedPages));

      const questions = section.questions;
      setQuestionsState(questions);
      validateAllSelections();
      setHeadingState({
        pageNumber: currentSectionNumber && parseInt(currentSectionNumber) || 1,
        name: section.name,
        image: section.hero[0].image
      });
    }

    redirectIfNonAcceptedTCs();

  }, [location, currentSectionNumber, configuration, questionsState]);


  if (!survey) return null;

  const PushDataLayerYesNoTypes=(index,answerIndex) => {
    PushDataLayerObject(configuration,
      GILD_EVENT,
      SURVEY_SECTION + "-" + currentSectionNumber,
      ANSWERED,
      ANSWERING_QUESTION + (parseInt(index) + 1) + ",#" + (parseInt(answerIndex) + 1)
    );
  }

  const onValueChange = (e, index, answerIndex, subquestion = null, id = null, proceed = false, isGrouped = false, questionNumberInGroup = -1) => {
   
    if (isGrouped===true && questionNumberInGroup > -1) {
      PushDataLayerObject(configuration,
        GILD_EVENT,
        SURVEY_SECTION + "-" + currentSectionNumber,
        ANSWERED,
        ANSWERING_QUESTION + (questionNumberInGroup) + ",#" + (parseInt(answerIndex) + 1)
      );
    }
    else {
      if (!subquestion) {
        if (proceed === false) {
          PushDataLayerObject(configuration,
            GILD_EVENT,
            SURVEY_SECTION + "-" + currentSectionNumber,
            ANSWERED,
            ANSWERING_QUESTION + (parseInt(index) + 1) + ",#" + (parseInt(answerIndex) + 1)
          );
        }
      }
    }

    const quest = Object.assign([], [...questionsState]);

    const quest2 = quest[index].answer.map((x, ind) => {

      if (answerIndex === 0 && id === Q_YES_NO_WITH_MULTIPLE_TEXT) {
        if (proceed) {
          if (ind === answerIndex) {
            x.answered = 'true';
          }
          quest[index].isValid = true;
          return x;
        } else {
          x.answered = 'false';
          return x;
        }

      } else {
        if (ind === answerIndex) {
          x.answered = 'true';
        } else {
          x.answered = 'false';
        }
        quest[index].isValid = true;
        return x;
      }
    })
    quest[index].answer = quest2

    if (subquestion && id === Q_YES_NO_WITH_TEXT) {
      quest[index].subquestion.answer[0].text = subquestion;
      quest[index].subquestion.answer[0].answered = 'true';
    }
    
    if (!subquestion && id === Q_YES_NO_WITH_TEXT) {
      quest[index].subquestion.answer[0].text = "";
      quest[index].subquestion.answer[0].answered = 'false';
      quest[index].showTextArea = true;
      subQuestionExists(index)
    }

    setQuestionsState(quest)

    if (survey) {
      const cSurveyIndex = survey.content.components.findIndex(x => x.id === SURVEY);
      const cSurvey = survey.content.components[cSurveyIndex];
      const nexSection = cSurvey.object.sections[parseInt(currentSectionNumber)];

      if (!nexSection) {
        if(additionalCommentsExist) {
          setLink(PATH_PAGE_SURVEY_ADDITIONAL_COMMENTS + PATH_PAGE_LOCALE + queryParam.locale);
        } else {
          setLink(PATH_PAGE_SURVEY_RESULT);
          const cCtaGetYourResultsSection = survey.content.components[cSurveyIndex + 2];
          setNextButtonText(cCtaGetYourResultsSection?.object?.text);
        }
      } else {
        setLink(PATH_PAGE_SURVEY_SECTION + ((parseInt(currentSectionNumber) + 1)) + PATH_PAGE_LOCALE + queryParam.locale)
      }
    }

    validateAllSelections();
    setshowValidation(false);
  }

  const subQuestionExists = (questionIndex) => {
    const quest = Object.assign([], [...questionsState]);
    const quest2 = quest[questionIndex].answer.map((x, ind) => {
      x.answered = 'false';
      x.isValid = false;
      return x;
    })
    quest[questionIndex].answer = quest2;
    setShowNext(false);
    setReRender(!reRender);
  }

  const handleNext = () => {
    setShowNext(false);

    const cSurveyIndex = survey.content.components.findIndex(x => x.id === SURVEY);
    const cSurvey = survey.content.components[cSurveyIndex];
    const nexSection = cSurvey.object.sections[parseInt(currentSectionNumber)];
   
    PushDataLayerObject(configuration, 
      GILD_EVENT,
      BUTTONS,
      CLICKED,
      nexSection 
      ? NEXT_SECTION + " " + (parseInt(currentSectionNumber) + 1) : additionalCommentsExist
      ? NEXT_ADDTIONAL_COMMENTS : NEXT_SURVEY_RESULTS
      );
      
    completedPages.push(parseInt(currentSectionNumber) - 1);
    dispatch(setCompletedPages(completedPages));

    let element = document.getElementById(0);
        element.tabIndex = 0;
        element.focus({ preventScroll: true });
  }

  const validate = () => {
    let array = [];
    questionsState.forEach((x, index) => {
      x.key = index;
      if (x.id == Q_YES_NO_WITH_MULTIPLE_TEXT) {
        if (x.answer[0].answered == 'true') {
          if (x.subquestion[0].answer[0].answer == 'true') {
            x.isValid = true;
            array.push(x);
          }
        }
        if (x.answer[1].answered == 'true') {
          x.isValid = true;
          array.push(x);
        }
        if (x.answer[0].answered == 'false' && x.answer[1].answered == 'false') {
          x.isValid = false;
          array.push(x);
        }
      }

      if (x.id == Q_YES_NO_WITH_TEXT) {
        if (x.answer[0].answered == 'true') {
          if (x.subquestion.answer[0].answer == 'true') {
            x.isValid = true;
            array.push(x);
          }
        }
        if (x.answer[1].answered == 'true') {
          x.isValid = true;
          array.push(x);
        }
        if (x.answer[1].answered == 'false' && x.answer[0].answered == 'false') {
          x.isValid = false;
          array.push(x);
        }
      }

      if (x.id === Q_MCQ) {
        if (x.isValid) {
          array.push(x)
        }
        else {
          x.isValid = false;
          array.push(x);
        }
      }
      if (x.id === Q_YES_NO) {
        if (x.isValid) {
          array.push(x)
        }
        else {
          x.isValid = false;
          array.push(x);
        }
      }
      if (x.id === Q_RATING) {
        if (x.isValid) {
          array.push(x)
        }
        else {
          x.isValid = false;
          array.push(x);
        }
      }
    });
    
    let found = false;
    questionsState.forEach((x, index) => {
      if (x.isValid == false && !found) {
        let x = document.getElementById(index);
        found = true;
        x.tabIndex = 0;
        x.scrollIntoView({ behavior: "smooth" });
        x.focus({ preventScroll: true });
      }
    })

    setQuestionsState(array)
    setshowValidation(true);
  }

  const validateAllSelections = () => {

    const cSurvey = survey.content.components.find(x => x.id === SURVEY);
    const section = cSurvey.object.sections[parseInt(currentSectionNumber) - 1];
    const questions = section.questions;

    questions && questions.forEach(question => {
      const selectedAnswer = question.answer.filter(x => x.answered === 'true');
      selectAnswers.push(...selectedAnswer);
    });

    const filteredQuestion2 = questions.filter(x => x.id != Q_RATING)

    if (selectAnswers.length === questions.length) {
      setShowNext(true);
    }
    else {
      setShowNext(false);
    }
  }

  const addMultitextItem = (thisQuestionIndex, value, thisAnswerIndex) => {

    const quest = Object.assign([], [...questionsState]);
    const answerOpt = {
      "text": value,
      "score": "T1",
      "scoreMessage": "",
      "answered": "true"
    }
    quest[thisQuestionIndex].subquestion[0].answer.push(answerOpt);

    quest[thisQuestionIndex].isValid = true;
    quest[thisQuestionIndex].answer[0].answered = 'true';
    quest[thisQuestionIndex].answer[1].answered = 'false';
    setReRender(!reRender);
  }

  const removeAllAnswers = (questionindex) => {
    const quest = Object.assign([], [...questionsState]);
    const allAnswers = quest[questionindex].subquestion[0].answer;
    if (allAnswers.length > 1) {
      let array = [];
      array.push(allAnswers[0])
      quest[questionindex].subquestion[0].answer = array;
    }
    setReRender(!reRender);
  }

  const removeAnswerfromIndex = (questionindex, answerIndex) => {
    const quest = Object.assign([], [...questionsState]);
    const allAnswers = quest[questionindex].subquestion[0].answer;
    if (allAnswers.length > 1) {
      let array = [];
      allAnswers.forEach((x, index) => {
        if (index != answerIndex) {
          array.push(x)
        }
      })
      quest[questionindex].subquestion[0].answer = array;
    }

    if (quest[questionindex].subquestion[0].answer.length === 1) {
      quest[questionindex].answer.forEach(x => {
        x.answered = 'false'
        x.isValid = false
      })
      setShowNext(false);
    }
    setReRender(!reRender);
  }

  const revertSubQuestionsAnswer = (thisQuestionIndex) => {
    const quest = Object.assign([], [...questionsState]);

    quest[thisQuestionIndex].subquestion.answer[0].text = '';
    quest[thisQuestionIndex].subquestion.answer[0].answered = '';

  }

  const getQuestionGroupInfo = (x, index, questionsState) => {
    let questionCountInGroup = 0;
    let questionIndexInGroup = 0;

    if(x.properties[0].grouped === 'true') {
        let groupTopIndex = 0;
        let groupBottomIndex = questionsState.length - 1;

        // Finding the first index of the set
        for(let i=index; i>=0; i--) {
          if(questionsState[i].properties[0].groupQuestion.length > 0) {
            groupTopIndex = i;
            break;
          }
        }

        // Finding the last index of the set
        if(index < questionsState.length - 1) {
          for(let j=index+1; j < questionsState.length; j++) {
            if(questionsState[j].properties[0].grouped == 'false' || questionsState[j].properties[0].groupQuestion?.length > 0) {
              groupBottomIndex = j - 1;
              break;
            }
          }
        }

        questionCountInGroup = groupBottomIndex - groupTopIndex + 1;
        questionIndexInGroup = index - groupTopIndex;
    }

    return { questionCountInGroup: questionCountInGroup, questionIndexInGroup: questionIndexInGroup };

  }

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const splittedLink = link?.split("//");

  if (splittedLink.length === 2) {
    const pageNumber = splittedLink[0].split(PATH_PAGE_SURVEY_SECTION)
    setLink(PATH_PAGE_SURVEY_SECTION + (parseInt(pageNumber[1]) + 1) + '/' + splittedLink[1]);
  }

  const uniqueCompletedPages = completedPages.filter(unique);

  const isExists = uniqueCompletedPages.includes((parseInt(currentSectionNumber) - 1));

  let newlink = '';

  if (isExists) {
    const cSurveyIndex = survey.content.components.findIndex(x => x.id === SURVEY);
    const cSurvey = survey.content.components[cSurveyIndex];
    const nexSection = cSurvey.object.sections[parseInt(currentSectionNumber)];
    if (!nexSection) {
      newlink = PATH_PAGE_SURVEY_ADDITIONAL_COMMENTS + PATH_PAGE_LOCALE + queryParam.locale;
    } else {
      newlink = PATH_PAGE_SURVEY_SECTION + (parseInt(currentSectionNumber) + 1) + PATH_PAGE_LOCALE + queryParam.locale;
    }
  }

  const focusScroll=(index)=>{
    const focus = document.getElementById(index);
    const card = focus.getElementsByClassName('card');
    
    for (let i = 0; i < questionsState.length; i++) {
      const previousNode = document.getElementById(i);
      const previousCardNode = previousNode.getElementsByClassName('card');
      if (i != index) {
        if (previousCardNode[0].className === 'card in-valid') {
          previousCardNode[0].setAttribute('class', 'card in-valid');
        }
        else {
          previousCardNode[0].setAttribute('class', 'card');
        }
      }
    }
    
    card[0].setAttribute('class', 'card card__focus');
    focus.scrollIntoView({ behavior: "smooth" });
  }
  
  const blurAndChange = (index) => {
    const focus = document.getElementById(index);
    const card = focus.getElementsByClassName('card');
  
    if (card[0].className === 'card card__focus') {
      card[0].setAttribute('class', 'card card__focus');
    } 
     if (questionsState[index].isValid === false) {
      card[0].setAttribute('class', 'card in-valid')
    }
     else {
       card[0].setAttribute('class', 'card');
     }
  }

  let questoinIndexLabels = [];
  let currentQuestionIndex = 1;
  let subQuestionIndex = 1;
  let counter = 1;
  questionsState.forEach((x) => {
    const { properties } = x || {};
    if (properties[0].grouped === 'true') {
      if (properties[0].groupQuestion) {
        questoinIndexLabels.push({ mainIndex: currentQuestionIndex, subString: currentQuestionIndex + "." + 1,subIndex:1 });
        counter = 1;
        subQuestionIndex = currentQuestionIndex;
        currentQuestionIndex++;
      } else {
        counter++;
        questoinIndexLabels.push({ mainIndex: subQuestionIndex, subString: subQuestionIndex + "." + counter, subIndex: counter });
      }
    }
    else {
      questoinIndexLabels.push({ mainIndex: currentQuestionIndex });
      currentQuestionIndex++;
    }
  });


  return (
    <_ScrollToTop>
      <div>
        <SectionComponent data={headingState} questionNumberLabels={translations} />
        <div className="question-wrapper">
          {questionsState.map((x, index) => {
           
            const mcqG = getQuestionGroupInfo(x, index, questionsState);
            const {questionCountInGroup , questionIndexInGroup} = mcqG;
            const questionIndexer = questoinIndexLabels[index];

            return (
              <div key={index} id={index} className={`question-container mx-auto md:mx-auto lg:mx-auto 2xl:mx-auto 3xl:mx-auto px-8 sm:px-10 py-6 ${x.id === Q_MCQ && x.properties[0].grouped === 'true' && 'grouped-question'}`}>
                <div onChange={() => blurAndChange(index)} onBlur={() => blurAndChange(index)} onFocus={()=> {focusScroll(index)} }>
                  {(x.id === Q_MCQ) && <MCQ questionIndexer={questionIndexer} questionNumberLabels={translations} question={x} componentId={x.id} questionIndex={index} onValueChange={onValueChange} register={register} questionCountInPage={questionCountInPage} questionCountInGroup={questionCountInGroup}  questionIndexInGroup={questionIndexInGroup} />}
                  {
                    (x.id === Q_YES_NO) && (
                      <YesNo questionIndexer={questionIndexer} questionNumberLabels={translations} question={x} questionIndex={index} componentId={x.id} onValueChange={onValueChange} register={register} questionCountInPage={questionCountInPage}/>
                    )
                  }
                  {
                    (x.id === Q_YES_NO_WITH_TEXT) && (
                      <YesNoWithText questionIndexer={questionIndexer} PushDataLayerYesNoTypes={PushDataLayerYesNoTypes} questionNumberLabels={translations} question={x} questionIndex={index} componentId={x.id} onValueChange={onValueChange} register={register} revertSubQuestionsAnswer={revertSubQuestionsAnswer} subQuestionExists={subQuestionExists} questionCountInPage={questionCountInPage}/>
                    )
                  }
                  {
                    (x.id === Q_YES_NO_WITH_MULTIPLE_TEXT) && (
                      <YesNoWithMultiText questionIndexer={questionIndexer} questionNumberLabels={translations} question={x} questionIndex={index} componentId={x.id} onValueChange={onValueChange} addMultitextItem={addMultitextItem} register={register} removeAnswerfromIndex={removeAnswerfromIndex} subQuestionExists={subQuestionExists} removeAllAnswers={removeAllAnswers} questionCountInPage={questionCountInPage}/>
                    )
                  }
                  {
                    (x.id === Q_RATING) && (
                    <Rating questionIndexer={questionIndexer} questionNumberLabels={translations} question={x} questionIndex={index} componentId={x.id} onValueChange={onValueChange} register={register} questionCountInPage={questionCountInPage}/>
                    )
                  }
                </div>
              </div>
            )
          })}
          <div className="next-btn-wrapper flex w-full text-center py-10 justify-center">
            {showNext ? <Link role="button" onClick={handleNext} className="w-1/5 sm:w-1/2 md:w-1/2 lg:w-1/3 py-3 rounded-full btn-primary font-semibold text-poppins text-base block"
              to={newlink ? newlink : link}>
              {nextButtonText}
          </Link> :
              <button role="button" onClick={validate} className="w-1/5 sm:w-1/2 md:w-1/2 lg:w-1/3 py-3 rounded-full btn-primary font-semibold text-poppins text-base block">
              {nextButtonText}
          </button>
            }
          </div>
        </div>
      </div>
    </_ScrollToTop>

  )
}

export default SectionComponent1;
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import ResultsDoc from './ResultsDoc';

const generatePdfDocument = async (pdfMeta, resultsState, additionalCommentsState, fileName) => {

        const blob = await pdf((
            <ResultsDoc
                title={fileName}
                pdfData = { pdfMeta } 
                results = { resultsState }
                additionalComments = { additionalCommentsState }
            />
        )).toBlob();
        saveAs(blob, fileName);
};

export default generatePdfDocument;
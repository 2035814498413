import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionComponent from '../Section/SectionComponent';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation, Switch } from 'react-router-dom';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
  }, [pathname]);
  return props.children
}

const SectionComponent1 = () => {
  const pageNumber = 5;
  const { register, handleSubmit, errors } = useForm();
  const configuration = useSelector((state) => state.configuration);
  const history = useHistory();
  const [resultsState, setResultsState] = useState([]);
  const [headingState, setHeadingState] = useState({});
  const dispatch = useDispatch();

  // useEffect(() => {

  // }, [configuration])
  const { survey } = configuration;

  useEffect(() => {
    console.info('useEffect configuration page resulsts ', configuration)
    if(survey) {
      const cSurvey = survey.content.components.find(x=> x.id === 'c-survey');
      // const results = cSurvey.object.results
      const results = cSurvey.object.sections;
      setResultsState(results);
      console.info('aaA: ', results)
  
    }
  }, [configuration])

  if(!survey) return null;

  return(
    <_ScrollToTop>
        <Fragment>
          <div className="text-center">
            {(resultsState || []).map((section, sectionIndex) => {
              return (
                <div key={sectionIndex}>
                  <div><strong>{section.name}</strong></div>
                  <div key={sectionIndex}>
                    {
                      section.questions.map((quest, questIndex) => {
                        const data = quest.answer.find(x =>x.answered === 'true');
                        const message = data && (data.score === 'T2' || data.score === 'T3')? "-" + data.scoreMessage: "";
                  
                        return (
                          <p key={questIndex}>{message}</p>
                        )
                      })
                    } 
                  </div>     
                
                </div>  
              )
            }) }  
          </div>
        </Fragment>
    </_ScrollToTop>

  )
}

export default SectionComponent1;
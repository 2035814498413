import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FooterComponent from './Footer';
import { FOOTER_COMPONENT } from '../../../constants';

const Footer = () => {
    const configuration = useSelector((state) => state.configuration);
  
    useEffect(() => {
    }, [configuration]);
  
    if(!configuration.content) return null;

    const footerLinks = configuration.content.content.labels.sections.find((e)=> e.name === "clickables");

    const { translations } = footerLinks || {};

    const allowedLinks = ['privacy', 'cookies', 'terms', 'contact'];

    const footerAllowedLinks = (translations || []).filter((e) => allowedLinks.indexOf(e.name) !== -1);

    return( 
        <FooterComponent data={footerAllowedLinks} />
      )
    
    
    
  }
  
  export default Footer;
import React from "react";
import ReactDOM from "react-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import TagManager from './gtm';
import reportWebVitals from './reportWebVitals';
import configurationReducer from './redux/reducers/configuration';
import { getConfiguration } from './redux/actions/configuration';
import rootSaga from './sagas';
import Main from './pages/Main';
import GlobalError from './components/organisms/GlobalError/GlobalError';
import { PATH_PAGE_LOCALE_DEFAULT, REACT_APP_GTM_ID, REACT_APP_KEY_COOKIE_SCRIPT, APP_LANGUAGE, APP_SUBJECT, APP_INTENDEDAUDIENCE } from './constants';

import './tailwind.compiled.css';

const url = window.location.href;
let splitedUrl = url.split('?');
let locale = splitedUrl[1]?.split('=')[1];

const getLocation = function(href) {
  var link = document.createElement("a");
  link.href = href;
  return link;
};
  
const link = getLocation(url);
  
  const pathname = link.pathname;
  
  if(!locale){
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?locale=${PATH_PAGE_LOCALE_DEFAULT}`;
    window.history.pushState({path:newurl},'',newurl);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  configurationReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);
store.dispatch(getConfiguration(locale));

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID
 }

// Cookie Consent Banner
const sdkScript = document.createElement('script');
sdkScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
sdkScript.type = 'text/javascript';
sdkScript.setAttribute('data-domain-script', REACT_APP_KEY_COOKIE_SCRIPT);
sdkScript.id = 'sdkScript';

const consentScript = document.createElement('script');
consentScript.src = `https://cdn.cookielaw.org/consent/${REACT_APP_KEY_COOKIE_SCRIPT}/OtAutoBlock.js`;
consentScript.type = 'text/javascript';
consentScript.id = 'consentScript';

document.head.appendChild(consentScript);
document.head.appendChild(sdkScript);
const defaultDatalayer = {
    'language':         APP_LANGUAGE,
    'subject':          APP_SUBJECT,
    'intendedAudience': APP_INTENDEDAUDIENCE
  };

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <GlobalError>
      <Main />
    </GlobalError>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

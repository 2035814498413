import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './index.scss'
import FooterComponent from './index';
import { Fragment } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { API, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING, TERM_AND_CONDITION, PRIVACY_POLICY, COOKIE_POLICY, CONTACT_US } from '../../../constants';
import { PushDataLayerObject} from '../../../utility';
import LazyLoadImage from '../../atoms/image/Image';

const Footer = ({data}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const configuration = useSelector((state) => state.configuration);
    const { content } = configuration || {};
    const pageContentTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'page-content').translations;
    const terms = pageContentTranslations?.find(x=> x.name === 'content-terms');
    const privacy = pageContentTranslations?.find(x=> x.name === 'content-privacy');
    const cookies = pageContentTranslations?.find(x=> x.name === 'content-cookies');
    const contact = pageContentTranslations?.find(x=> x.name === 'content-contact');
    const footerTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'footer');
    const footerTexts = footerTranslations?.footerTexts;
    const footerCopyright = footerTranslations?.footerCopyright;
    const footerImage = footerTranslations?.image;
    const footerLink = footerTranslations?.footerLink;
    const otherLinks = footerTranslations?.otherLinks;

    const pageContent = {
      'terms': terms,
      'privacy': privacy,
      'cookies': cookies,
      'contact': contact
    };

    const onOpenModal = (origin) => {
        pageContent[origin] && function() {
            setModalContent(pageContent[origin]?.text);
            setModalTitle(pageContent[origin]?.heading);
            setModalOpen(true);
        }();

        switch(origin) {
          case 'terms': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + TERM_AND_CONDITION);
            return;
          }
          case 'privacy': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + PRIVACY_POLICY);
            return;
          }
          case 'cookies': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + COOKIE_POLICY);
            return;
          }
          case 'contact': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + CONTACT_US);
            return;
          }
          default: {
            return;
          }
        }
      };
  
    const onCloseModal = () => {
      setModalOpen(false);
    };

    const modalClassNames = {
        modal: 'rounded-2xl pt-10 px-8',
        closeIcon: 'optincta-modal__close-icon'
      }
  
  return(   <>
    <Modal open={modalOpen} onClose={onCloseModal} classNames={modalClassNames}>
    <h2 tabIndex="0" className={'optincta-modal__title font-semibold mb-6 spotlight'}>{modalTitle}</h2>
    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
    </Modal>

    <Fragment>
        { (data || []). length > 0 ? <footer className="mx-auto">
        <div>
          <div className="py-3 flex md:flex md:items-center md:justify-center " >
            <ul className="w-full text-center">
              {  
                  (data || []).map((e, i) => {
                      const { name, text } = e || {};
                      const classes = ((data || []).length === i+1) ? "border-0" : "border-r";
                      return (
                      <li className={`px-4 sm:px-3 ${classes} capitalize inline leading-7 text-sm`} key={ i }>
                            <a className="text-sm font-poppins" href="#" onClick={(e)=>{ e.preventDefault();onOpenModal(name);}}>{ text }</a>
                      </li>
                      )
                  })
              }
            </ul>
          </div>
          <div className="w-full text-center mb-2 logo">
            <div>
              {(footerTexts || []).map((footerText, footerTextIndex) => {
                return(
                  <p className={`px-4 sm:px-3 border-0 inline leading-7 text-sm`} key={footerTextIndex}><span className="text-sm font-poppins">{footerText}</span></p>
                  )
                })
              }
              <div className="otherLinks">
                <a href={footerLink} target="_blank">
                  <picture className={`max-auto logoImage`}>
                    <source media="(min-width:768px)" srcSet={`${API}/images/${footerImage.Desktop}`}/>
                    <source media="(min-width:767px)" srcSet={`${API}/images/${footerImage.Mobile}`}/>
                    <LazyLoadImage className="text-sm font-poppins mx-auto" width={"85"} src={`${API}/images/${footerImage.Desktop}`} alt={footerImage.altText} aria-label={footerImage.altText}/>
                  </picture>
                </a>
                {(otherLinks || []).map((otherLink, otherlinkIndex) => {
                  return(
                    <a href={otherLink.link} target="_blank" key={otherlinkIndex} className="otherLink">
                      <LazyLoadImage className="text-sm font-poppins mx-auto" width={"170"} src={`${API}/images/${otherLink.image}`} alt={otherLink.altText} aria-label={otherLink.altText}/>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="w-full text-center mb-2 logo">
            <p className="px-4 sm:px-3 border-0 inline leading-7 text-sm">
                <span className="text-sm font-poppins">{footerCopyright}</span>
            </p>
        </div>
        <div className="w-full text-center mb-2 logo">
            <a className="ot-sdk-show-settings link underline">gerenciar configurações de cookies</a>
        </div>
        </div>
    </footer> : "" }
    </Fragment>
    </>
)
}

export default Footer;
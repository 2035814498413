import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation, Switch } from 'react-router-dom';
import generatePDFDocument from './generatePdfDocument';
import { PushDataLayerObject } from '../../../utility';
import { SURVEY, Q_ADDITIONAL_COMMENTS, PATH_REDIRECT_USER,PATH_PAGE_FEEDBACK, GILD_EVENT ,BUTTONS,CLICKED, DOWNLOAD_REPORT, PATH_PAGE_LOCALE, API } from '../../../constants';

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
    }, [pathname]);
    return props.children
  }

const DownloadReportCTA = ({ data, page }) => {

    const { object } = data || {}; //JSON config of the component
    const acceptedTCs = useSelector((state) => state.acceptedTCs);
    const configuration = useSelector((state) => state.configuration);
    const history = useHistory();
    const [resultsState, setResultsState] = useState([]);
    const [additionalCommentsState, setAdditionalCommentsState] = useState('');
    const dispatch = useDispatch();
  
    const redirectIfNonAcceptedTCs = () => {
        if(acceptedTCs === false){
            history.push(PATH_REDIRECT_USER);
        }
    }
    
    const { survey } = configuration;
    const { surveyResultsPage } = configuration || {};

    const { content } = surveyResultsPage || {};
    const { pdfMeta } = content || {};

    useEffect(() => {
        redirectIfNonAcceptedTCs();

        let isMounted = true; // note this flag denote mount status
            if (isMounted) {
                if(survey) {
                const cSurvey = survey.content.components.find(x=> x.id === SURVEY);
                const cAdditionalComments = survey.content.components.find(x=> x.id === Q_ADDITIONAL_COMMENTS);
                // const results = cSurvey.object.results
                const results = cSurvey.object.sections;
                setResultsState(results);
                setAdditionalCommentsState(cAdditionalComments);
            }
        }
        isMounted = false;
    }, [configuration]);

    // Render this component only if the survey data is available
    if(!survey) return null;

    var styled = StyleSheet.create({
        hidden: {
            display:'none'
        },
        result: {
            textAlign: 'left',
            border: '2px solid white',
            padding: '10px'
        },
        section: {
            color: 'red'
        },
        question: {
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        data: {
            color: '#8ef6fc'
        },
        message: {
            color:'#fafc8e'
        },
        flexContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            backgroundColor: "#F3F3F3",
            marginTop: "50px"
        },
        flexRow: {
            flex: "1 50%",
            textAlign: "left",
            padding: "50px 50px 0px 50px"
        },
        resultImage: {
            position: 'absolute',
            top: "48px",
            left: "15px"
        }
      });

    const url = window.location.href;
    let splitedUrl = url.split('?');
    let locale = splitedUrl[1]?.split('=')[1];
    let localeUrl = `${PATH_PAGE_LOCALE}${locale}`;

    const logGA = () => {
        PushDataLayerObject(configuration, GILD_EVENT, BUTTONS, CLICKED, DOWNLOAD_REPORT)
    }

    const blobResultsStateData = new Blob(['{ "survey": ' + JSON.stringify(resultsState) + ', "additionalComments":' + JSON.stringify(additionalCommentsState) + '}'],{type:'application/json'});
    const hrefResultsStateData = URL.createObjectURL(blobResultsStateData);

    const resultsTierContent = survey.content.components.find(x=> x.id === SURVEY).object.results;
    const resultAdditionalComments = resultsTierContent.find(x =>x.id === 'additionalComments').content;
    return (
        <_ScrollToTop>
            <Fragment>
            <div className='cta-div text-center'>                
                <button onClick={() => { logGA(); generatePDFDocument({ pdfMeta }, { resultsState }, { additionalCommentsState }, `${pdfMeta.pdfFileName}`); history.push(PATH_PAGE_FEEDBACK + localeUrl) }}
                className="place-content-center mt-2 text-center bg-primary-900 hover:bg-primary-600 transition-colors duration-200 rounded-full font-semibold py-4 px-6 xs:min-w-17 2xs:min-w-20 3xs:min-w-22 inline-flex cta"
                >
                {object.text}
                </button>
                <div>
                    <div style={styled.flexContainer}>
                    {(resultsState || []).map((section, sectionIndex) => {
                        let sectionTier = "T1";
                        let sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                        return (
                            
                            <div key={sectionIndex} style={styled.flexRow}>
                                <div>
                                    <p><strong>{section.name}</strong></p>
                                    <div key={sectionIndex}>
                                        {
                                        section.questions.map((quest, questIndex) => {
                                            const answer = quest.answer.find(x =>x.answered === 'true');
                                            const message = answer && ( answer.score === 'T2' || answer.score === 'T3')? "- " + answer.scoreMessage: "";
                                            if (answer.score === 'T2' && sectionTier === "T1")
                                            {
                                                sectionTier = answer.score;
                                                sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                                            }
                                            if (answer.score === 'T3' && sectionTier != "T3")
                                            {
                                                sectionTier = answer.score;
                                                sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                                            }
                                            if(!answer) return;

                                            return (
                                                (answer.score != "T1") ? 
                                            <div key={questIndex}>
                                                <p>{message}</p>
                                            </div>
                                            : ""
                                            )
                                        })
                                        } 
                                        {(sectionTier === "T1")? <p>- {resultsTierContent.find(x => x.id === "pass").content}</p>:""}
                                    </div>
                                </div>
                                <img style={styled.resultImage} src={sectionTierImage} />

                                <div style={styled.hidden}>
                                    //Below code is for future - Tier based messages.
                                    <div style={styled.result}><strong>{section.name}</strong></div>
                                    <div key={sectionIndex}>
                                        {
                                        section.questions.map((quest, questIndex) => {
                                            const answer = quest.answer.find(x =>x.answered === 'true');
                                            const message = answer && (answer.score === 'T1' || answer.score === 'T2' || answer.score === 'T3')? "-" + answer.scoreMessage: "";
                                            
                                            if(!answer) return;

                                            return (
                                            <div key={questIndex} style={styled.result}>
                                                <p>Question: {quest.question}</p>
                                                <p>Answer: {answer.text}</p>
                                                <p>MESSAGE: {message}</p>
                                            </div>
                                            )
                                        })
                                        } 
                                    </div> 
                                </div>
                            </div>
                        ) 
                        })}
                        <div style={styled.flexRow}>
                            <div>
                                <p><strong>{additionalCommentsState.heading}</strong></p>
                                <div>
                                    <div>
                                        <p>{(additionalCommentsState.object?.answer?.text?.trim()) ? additionalCommentsState.object?.answer?.text: "- " + resultAdditionalComments}</p>
                                    </div>
                                </div>
                            </div>
                            <img style={styled.resultImage} src={ API+'/images/'+resultsTierContent.find(x =>x.id === 't1').content} />
                        </div> 
                    </div>
                </div>
            </div>
        </Fragment>
        </_ScrollToTop>
    )    
}

export default DownloadReportCTA;